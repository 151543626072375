import React from "react"
import * as api from "api/guests"
import pluralize from "pluralize"
import { useHistory } from "react-router-dom"
import { eventGuestPortalPath, eventGuestsPath } from "utilities/routes"
import { asDate } from "utilities/strings"
import { Guest, GuestPortalHash, GuestPortalSubpath } from "sharedTypes"
import { popToast, ToastProps } from "utilities/toasts"
import {
  Divider, ListItem, Text, UnorderedList, Box,
} from "@chakra-ui/react"
import UpgradeAlert from "components/elements/UpgradeAlert"
import Link from "components/elements/Link"
import { useCurrentEvent } from "queries/events"
import useGuestsStore from "services/useGuestsStore"
import { shallow } from "zustand/shallow"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import TextField from "components/ReactHookForm/TextField"
import { useQueryClient } from "react-query"
import { guestCacheKey } from "queries/guests"
import { DateFormat } from "utilities/constants"
import EmailProperty from "../EmailProperty"
import GuestCard from "../../../SelectedGuest/Show/GuestCard"
import EmailHeader from "../EmailHeader"

type Props = {
  onClose: () => void;
  selectedCount?: number;
  guest?: Guest;
}

const SendSmartReminder = ({ onClose, selectedCount, guest }: Props) => {
  const { data: currentEvent } = useCurrentEvent()
  const history = useHistory()
  const queryClient = useQueryClient()

  const [selectedGuestIds, deselectAll, allSelected, groupId, segmentId] = useGuestsStore(
    (s) => [s.selectedGuestIds, s.deselectAll, s.allSelected, s.groupId, s.segmentId],
    shallow,
  )

  let sendTo
  let toastMessage

  if (guest) {
    sendTo = <GuestCard guest={guest} />
    toastMessage = (): ToastProps => ({
      title: `Successfully sent a Smart Reminder to ${guest.personInfo.firstName} ${guest.personInfo.lastName}`, status: "success",
    })
  } else {
    sendTo = <EmailProperty name="To">{selectedCount} selected {pluralize("guest", selectedCount)}</EmailProperty>
    toastMessage = (guestCount): ToastProps => {
      if (!guestCount) {
        return { title: "Smart Reminders were not sent because selected guests are unsubscribed.", status: "warning" }
      }

      return { title: `Successfully sent a Smart Reminder to ${guestCount} ${pluralize("guest", guestCount)}`, status: "success" }
    }
  }

  const initialValues = {
    subject: `Reminder: ${currentEvent.title} on ${asDate(currentEvent.startDate, DateFormat.DAY_OF_WEEK_MONTH_DAY)}`,
  }

  const onSubmit = ({ subject }) => api.postGuestsSmartReminders(
    currentEvent.id,
    subject,
    allSelected ? undefined : (guest ? [guest.id] : selectedGuestIds),
    allSelected ? segmentId : undefined,
    allSelected ? groupId : undefined,
  ).then(({ data: { guestCount } }) => {
    if (!guest) {
      history.push(eventGuestsPath(currentEvent.id))
      deselectAll()
    } else {
      queryClient.removeQueries(guestCacheKey(guest.id))
    }

    popToast(toastMessage(guestCount))
    onClose()
  }).catch(() => {
    // API service automatically alerts the user, show must go on without closing the modal
  })

  const {
    emailNotificationsSenderName,
    emailNotificationsReplyTo, privateEmailsEnabled,
  } = currentEvent

  return (
    <ModalForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      onClose={onClose}
      title="Send Smart Reminder"
      submitText="Send"
      hideActions={!privateEmailsEnabled}
    >
      {({ control }) => (
        <>
          {privateEmailsEnabled && (
            <>
              <EmailHeader
                sendTo={sendTo}
                event={currentEvent}
                from={emailNotificationsSenderName}
                replyTo={emailNotificationsReplyTo}
              />
              <TextField
                variant="outline"
                name="subject"
                control={control}
                label="Subject"
                placeholder="Enter the subject of your Smart Reminder"
                mb={8}
              />
              <Divider mb={8} />
            </>
          )}
          <Text mb={6}>A Smart Reminder is customized for every guest and includes:</Text>
          <UnorderedList mb={6}>
            <ListItem>Event name, date, and location</ListItem>
            <ListItem>Guest confirmation status</ListItem>
            <ListItem>Guest name and email</ListItem>
            <ListItem>Purchased ticket or RSVP</ListItem>
            <ListItem>Seating information *</ListItem>
            <ListItem>QR code (if guest is confirmed) *</ListItem>
            <ListItem>Calendar invite *</ListItem>
            <ListItem>Custom message *</ListItem>
          </UnorderedList>
          <Text fontSize="sm">
            * See your Guest Portal:{" "}
            <Link
              to={eventGuestPortalPath(
                currentEvent.id,
                GuestPortalSubpath.EmailResponders,
                GuestPortalHash.GuestConfirmationSettings,
              )}
            >Guest Confirmation Settings
            </Link>
          </Text>
          {!privateEmailsEnabled
            && <Box mt={6}><UpgradeAlert text="This feature requires a Professional Subscription." /></Box>}
        </>
      )}
    </ModalForm>
  )
}

export default SendSmartReminder
