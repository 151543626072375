import React from "react"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import * as Yup from "yup"
import { FormsTerminologyModalProps } from "sharedTypes"
import EditModalSegment from "../../pages/EventShow/GuestPortal/Terminology/FormSpecificMessagingSection/EditModalSegment"
import TranslationField from "../../pages/EventShow/GuestPortal/Terminology/TranslationField"

const RsvpFormsModal = ({
  title, event, onClose, onSubmit, defaultTranslations,
}: FormsTerminologyModalProps) => {
  const PrivateRsvpFormsModalSchema = Yup.object().shape({})

  return (
    <ModalForm
      title={`Messaging: ${title}`}
      onClose={onClose}
      initialValues={event}
      validationSchema={PrivateRsvpFormsModalSchema}
      onSubmit={onSubmit}
    >
      {({ control }) => (
        <>
          <EditModalSegment title="Guest Selection" first>
            <TranslationField
              label="Confirm"
              defaultTranslation={defaultTranslations.rsvpConfirm}
              fieldName="translations.rsvpConfirm"
              editable
              control={control}
            />
            <TranslationField
              label="Decline"
              defaultTranslation={defaultTranslations.rsvpDecline}
              fieldName="translations.rsvpDecline"
              editable
              control={control}
            />
          </EditModalSegment>
          <EditModalSegment title="Plus-One Registration">
            <TranslationField
              label="Plus-One Registration"
              defaultTranslation={defaultTranslations.rsvpPlusOneLabel}
              fieldName="translations.rsvpPlusOneLabel"
              editable
              control={control}
            />
            <TranslationField
              label="Response Yes"
              defaultTranslation={defaultTranslations.rsvpPlusOneYes}
              fieldName="translations.rsvpPlusOneYes"
              editable
              control={control}
            />
            <TranslationField
              label="Response No"
              defaultTranslation={defaultTranslations.rsvpPlusOneNo}
              fieldName="translations.rsvpPlusOneNo"
              editable
              control={control}
            />
          </EditModalSegment>
          <EditModalSegment title="Form Button">
            <TranslationField
              label="Submit Button Label"
              defaultTranslation={defaultTranslations.rsvpSubmitButton}
              fieldName="translations.rsvpSubmitButton"
              editable
              control={control}
            />
          </EditModalSegment>
          <EditModalSegment title="Form Messages" last>
            <TranslationField
              label="Success Message"
              defaultTranslation={defaultTranslations.rsvpSuccessMessage}
              fieldName="translations.rsvpSuccessMessage"
              editable
              control={control}
            />
            <TranslationField
              label="Form Edited Message"
              defaultTranslation={defaultTranslations.rsvpEditMessage}
              fieldName="translations.rsvpEditMessage"
              editable
              control={control}
            />
            <TranslationField
              label="Closing Message"
              defaultTranslation={defaultTranslations.rsvpClosingMessage}
              fieldName="translations.rsvpClosingMessage"
              editable
              control={control}
            />
          </EditModalSegment>
        </>
      )}
    </ModalForm>
  )
}

export default RsvpFormsModal
