import React, { useEffect, useState } from "react"
import { Box, Flex } from "@chakra-ui/react"
import Modal from "components/dialogs/Modal"
import { DioboxEvent, TextMessage } from "sharedTypes"
import { useGuests } from "queries/guests"
import Spinner from "components/elements/Spinner"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { SENDER_PHONE_NUMBER } from "utilities/constants"
import { asDate } from "utilities/strings"
import useMergeTags from "services/useMergeTags"
import Phone from "./Phone/Phone"
import GuestSelector from "./GuestSelector"
import SendTestMessage from "./SendTestMessage"

type Props = {
  onHide: () => void
  event: DioboxEvent
  textMessage: TextMessage
  enabled: boolean
}

export const FIRST_X_NUMBER_OF_GUESTS_TO_SHOW = 10

const PreviewTextMessageSchema = Yup.object().shape({
  changeMe: Yup.string().matches(/^[^A-Za-z]*$/).min(10),
})

const PreviewTextMessage = ({
  onHide, event: { title, startDate, id: eventId },
  textMessage: { body },
  enabled,
}: Props) => {
  const [selectedGuestIndex, setSelectedGuestIndex] = useState(0)
  const { guests, isLoading } = useGuests(eventId)
  const {
    control, formState: { isSubmitting, isValid },
  } = useForm({
    defaultValues: { changeMe: "" },
    resolver: yupResolver(PreviewTextMessageSchema),
    mode: "onChange",
  })
  const [bodyWithExampleData, setBodyWithExampleData] = useState("")
  const { replaceMergeTags } = useMergeTags({ eventId })

  const firstXGuests = guests.length > 0 ? guests.slice(0, FIRST_X_NUMBER_OF_GUESTS_TO_SHOW) : []
  const selectedGuest = firstXGuests.length > 0 ? firstXGuests[selectedGuestIndex] : null
  const date = asDate(startDate)!

  useEffect(() => {
    setBodyWithExampleData(
      replaceMergeTags(body, title, date, selectedGuest),
    )
  }, [selectedGuest])

  return (
    <Modal size="5xl" title="Preview" show noPadding hide={onHide}>
      {isLoading ? <Spinner /> : (
        <Flex>
          <Flex w="60%" borderRightWidth={1} justifyContent="center" pt={14}>
            <Phone phoneNumber={SENDER_PHONE_NUMBER} text={bodyWithExampleData} />
          </Flex>
          <Box flex={1} pt={8}>
            {firstXGuests.length > 0 && (
            <GuestSelector
              selectedGuest={firstXGuests[selectedGuestIndex]}
              selectedGuestIndex={selectedGuestIndex}
              setSelectedGuestIndex={setSelectedGuestIndex}
              guestCount={firstXGuests.length}
            />
            )}
            <SendTestMessage
              control={control}
              isSubmitting={isSubmitting}
              disabled={!(isValid && enabled)}
            />
          </Box>
        </Flex>
      )}

    </Modal>
  )
}

export default PreviewTextMessage
