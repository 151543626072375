import React from "react"
import UpgradeAlert from "components/elements/UpgradeAlert"
import { Control } from "react-hook-form"
import FormDetailHeader from "./FormDetailHeader"
import BasicInputs from "./BasicInputs"

type Props = {
  name: string
  showUpgradeAlert: boolean
  control: Control
}

const Survey = ({ name, showUpgradeAlert, control }: Props) => (
  <>
    <FormDetailHeader
      name={name}
      description="A survey form is distributed through Diobox Emails, generating a unique link for each
        guest to track their responses."
    />
    {showUpgradeAlert && <UpgradeAlert noBorder mb={8} text="Requires Corporate Subscription" />}
    <BasicInputs control={control} />
  </>
)

export default Survey
